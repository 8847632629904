/* 
 * Duff Framework
 * ©Selltec Communications GmbH
 */

/*Schrift*/

@font-face {font-family: notosans;                                  // Schriftfamilie
            src: url("../files/font/NotoSans-Regular.ttf");}       
@font-face {font-family: notosans_bold;                             // Schriftschnitt fett 
            src: url("../files/font/NotoSans-Bold.ttf");}          
@font-face {font-family: notosans_italic;                           // Schriftschnitt kursiv 
            src: url("../files/font/NotoSans-Italic.ttf");} 
@font-face {font-family: notosans_bold_italic;                      // Schriftschnitt fett & kursiv 
            src: url("../files/font/NotoSans-BoldItalic.ttf");} 
